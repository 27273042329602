export const roles = [
  {
    role: 'Company User',
    access: [
      {
        verb: 'write',
        scopes: [
          'dashboard',
          'style',
          'item',
          'report',
          'analytics',
          'marketing',
          'orders',
          'compliance',
        ],
      },
      {
        verb: 'read',
        scopes: [
          'dashboard',
          'style',
          'item',
          'report',
          'analytics',
          'marketing',
          'orders',
          'compliance',
        ],
      },
    ],
  },
  {
    role: 'Company Admin',
    access: [
      {
        verb: 'write',
        scopes: ['admin'],
      },
      {
        verb: 'read',
        scopes: ['admin'],
      },
    ],
  },
  {
    role: 'Test User',
    access: [
      {
        verb: 'write',
        scopes: [],
      },
      {
        verb: 'read',
        scopes: ['dashboard', 'style/workflow'],
      },
    ],
  },
  {
    role: 'Test Admin',
    access: [
      {
        verb: 'write',
        scopes: ['dashboard', 'admin', 'style', 'style/workflow'],
      },
      {
        verb: 'read',
        scopes: ['dashboard', 'admin', 'style', 'style/workflow'],
      },
    ],
  },
];
