import { createTheme } from '@mui/material';
import { palette } from './palette';
import { typography } from './typography';
import { breakpoints } from './breakpoints';

export const theme = createTheme({
  palette,
  typography,
  spacing: [0, 4, 8, 12, 16, 24, 32, 64],
  breakpoints,
});
