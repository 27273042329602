import { createStore, createTypedHooks } from 'easy-peasy';
import { userExampleStore } from 'features/userExampleFeature';
import { GlobalStore } from './types';
import { meStore } from './me';

// import and add feature stores to the global store
const globalStores: GlobalStore = {
  userExample: userExampleStore,
  me: meStore,
};

// Export typed hooks
export const typedHooks = createTypedHooks<GlobalStore>();
export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;

// Create and export store
export const globalStore = createStore<GlobalStore>(globalStores);
