/* istanbul ignore file -- @preserve */
import styled from '@emotion/styled';
import { useRegisterSW } from 'virtual:pwa-register/react';

export const ReloadPrompt = () => {
  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      if (r) {
        setInterval(() => {
          r.update();
        }, 1000 * 60);
      }
    },
  });

  const close = () => {
    setOfflineReady(false);
    setNeedRefresh(false);
  };

  return (
    <Container>
      {(offlineReady || needRefresh) && (
        <Toast>
          <Message>
            {offlineReady ? (
              <span>App ready to work offline</span>
            ) : (
              <span>New content available, click on reload button to update.</span>
            )}
          </Message>
          {needRefresh && (
            <ToastButton onClick={() => updateServiceWorker(true)}>Reload</ToastButton>
          )}
          <ToastButton onClick={() => close()}>Close</ToastButton>
        </Toast>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 0;
  margin: 0;
  width: 0;
  height: 0;
`;

const Toast = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 16px;
  padding: 12px;
  border: 1px solid #8885;
  border-radius: 4px;
  z-index: 1;
  text-align: left;
  box-shadow: 3px 4px 5px 0 #8885;
  background-color: white;
`;

const Message = styled.div`
  margin-bottom: 8px;
`;

const ToastButton = styled.button`
  border: 1px solid #8885;
  outline: none;
  margin-right: 5px;
  border-radius: 2px;
  padding: 3px 10px;
`;
