import { useCallback, useEffect } from 'react';
import type { ScriptInjectorProps, ScriptTagAttributes } from './types';

export const useScriptInjector = ({
  id,
  method,
  src,
  code,
  targetElement = 'body',
  attributes = {},
  callback,
}: ScriptInjectorProps) => {
  // Create new script, add an ID and add attributes to the script element.
  const setupScript = useCallback(() => {
    const script = document.createElement('script');
    script.id = id;

    Object.keys(attributes).forEach(key => {
      const value = attributes[key as keyof ScriptTagAttributes];
      if (value !== undefined) {
        script.setAttribute(key, value.toString());
      }
    });

    return script;
  }, [id]);

  // Append script to the DOM.
  const appendScript = useCallback(
    (script: HTMLScriptElement) => {
      if (typeof targetElement !== 'string') return;

      if (targetElement === 'body') {
        document.body.appendChild(script);
        return;
      }

      if (targetElement === 'head') {
        document.head.appendChild(script);
        return;
      }

      const el = document.getElementById(targetElement);
      el?.appendChild(script);
    },
    [setupScript, targetElement]
  );

  // Load a script by providing it with a source.
  const loadWithSrc = useCallback(() => {
    if (!src) return;
    const newScript = setupScript();
    newScript.src = src;

    appendScript(newScript);
  }, [appendScript]);

  // Load a script by providing it with code.
  const loadWithCode = useCallback(() => {
    if (!code) return;
    const newScript = setupScript();
    newScript.textContent = code;

    appendScript(newScript);
  }, [appendScript]);

  // Add script on load
  useEffect(() => {
    // Check if the script already exists in the DOM.
    const existingScript = document.getElementById(id);

    // Load new script
    if (!existingScript) {
      method === 'with-src' ? loadWithSrc() : loadWithCode();
      callback && callback();
    }
  }, [setupScript]);
};
