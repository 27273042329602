import React from 'react';
import { StoreProvider } from 'easy-peasy';
import { globalStore } from 'stores/easyPeasy';

type EasyPeasyProviderProps = {
  children: React.ReactNode;
};

export const EasyPeasyProvider = ({ children }: EasyPeasyProviderProps) => {
  return <StoreProvider store={globalStore}>{children}</StoreProvider>;
};
