import { useLocation } from 'react-router-dom';
import { checkAccess } from '../utils';

type AccessControlProps = {
  children: React.ReactNode;
  write?: boolean;
  fallback?: React.ReactNode;
  scope?: string;
  roleNames?: string[] | null;
};

export const AccessControl = ({
  children,
  write = false,
  fallback = null,
  scope = '',
  roleNames = null,
}: AccessControlProps) => {
  const location = useLocation();
  const hasAccess = checkAccess(scope ? scope : location.pathname, write, roleNames);
  if (!hasAccess) {
    return fallback;
  }
  return children;
};
