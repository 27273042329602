import { create, CLIENT_ERROR, SERVER_ERROR, TIMEOUT_ERROR, NETWORK_ERROR } from 'apisauce';
import { DOMAIN } from 'config';
import { getUserCookies } from 'features/auth';
import type { ApiResponse } from 'apisauce';
import { object, string, array } from 'yup';
import { ErrorType } from './types';

export const api = create({
  baseURL: DOMAIN,
  headers: {
    Accept: 'application/json',
    'Cache-Control': 'no-cache',
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${getUserCookies().accessToken}`,
  },
  timeout: 180000,
});

export const setAuthTokenToHeader = ({ token }: { token: string }) => {
  if (!token) throw new Error('No token found');
  api.setHeader('Authorization', `Bearer ${token}`);
};

export async function handleRequest<T>(promise: Promise<ApiResponse<T, T>>) {
  const res = await promise;
  if (!res.ok) {
    const errorMessage = await validateError(res as ApiResponse<ErrorType, ErrorType>);
    throw new Error(errorMessage);
  }
  const inValid = validateResponse(res);
  if (inValid || !res.data) {
    throw new Error(inValid);
  }
  return res.data;
}

function validateResponse<T>(res: ApiResponse<T, T>) {
  const contentType = res?.headers?.['content-type'];
  if (res?.config?.method === 'get') {
    if (!contentType?.includes('application/json')) {
      return `Invalid response type (${contentType}), expected application/json.`;
    }
    if (!res?.data) {
      return 'No data in response.';
    }
  }
  return '';
}

const validateError = async (res: ApiResponse<ErrorType, ErrorType>) => {
  if (res.problem === CLIENT_ERROR) {
    const errorMessage = await validateClientError(res as ApiResponse<ErrorType, ErrorType>);
    return errorMessage;
  }
  if (res.problem === SERVER_ERROR) {
    return `Unknown server error (${res.status}).`;
  }
  if (res.problem === TIMEOUT_ERROR) {
    return `Server didn't respond in time (${
      res.config?.timeout ? res.config.timeout / 1000 : 0
    } seconds).`;
  }
  if (res.problem === NETWORK_ERROR) {
    return 'Internet connection error.';
  }
  return `${res.problem} (${res.status}).`;
};

const errorSchema = object({
  Error: string().required(),
  Message: string().required(),
  Fields: array().of(object({ Name: string().required(), Message: string().required() })),
});

const validateClientError = async (res: ApiResponse<ErrorType, ErrorType>) => {
  try {
    await errorSchema.validate(res.data, { abortEarly: false });
  } catch (error) {
    const messages: string[] = [];
    (error as { inner: Error[] }).inner.forEach((innerError: Error) => {
      messages.push(innerError.message);
    });
    return `Invalid error response (${res.status}). ${messages.join(', ')}.`;
  }
  return res?.data?.Message;
};
