import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <Container>
      <Link to="/terms-and-conditions">Terms & conditions</Link>
      <Details>
        <div>Last updated: 2021-06-21</div>
        <Link to="/oss-software">© Delogue PLM 2012-2024</Link>
        <div>Version 1.142.0</div>
      </Details>
    </Container>
  );
};

export const Container = styled.footer`
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  background-color: #f5f5f5;
`;

export const Details = styled.div`
  display: flex;
  gap: 1rem;
`;
