import { Link } from 'react-router-dom';
import type * as T from '../types';
import styled from '@emotion/styled';

export const MenuItem = ({ url, children }: T.MenuItem) => {
  return (
    <Item>
      <Icon />
      <Link to={url}>{children}</Link>
    </Item>
  );
};

const Item = styled.li`
  position: relative;
  aspect-ratio: 1 / 0.8;
  display: flex;
  width: 100px;
  font-size: 0.8rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  gap: 0.25rem;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #e5e5e5;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

// Placeholder until we have icons
const Icon = styled.span`
  width: 40px;
  height: 40px;
  background-color: #000;
  border-radius: 0.4rem;
`;
