import { AuthProvider } from 'providers/authProvider';
import { App } from 'app';
import { AdminRoutes } from './admin';
import { AnalyticsRoutes } from './analytics';
import { ComplianceRoutes } from './compliance';
import { DashboardRoutes } from './dashboard';
import { ItemRoutes } from './item';
import { MarketingRoutes } from './marketing';
import { OrdersRoutes } from './orders';
import { ReportsRoutes } from './reports';
import { StyleRoutes } from './style';
import { ExampleRoutes } from './example';
import { ErrorBoundary } from 'components';

export const AppRoutes = [
  {
    path: '/',
    element: (
      <ErrorBoundary>
        <AuthProvider>
          <App />
        </AuthProvider>
      </ErrorBoundary>
    ),
    children: [
      DashboardRoutes,
      StyleRoutes,
      ItemRoutes,
      OrdersRoutes,
      ReportsRoutes,
      AnalyticsRoutes,
      MarketingRoutes,
      ComplianceRoutes,
      AdminRoutes,
      ExampleRoutes,
    ],
  },
];
