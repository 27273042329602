/* istanbul ignore file -- @preserve */

import React from 'react';
import { useQuery, useMutation, useQueryClient, UseMutationOptions } from '@tanstack/react-query';
import {
  AuthLoaderProps,
  ReactQueryAuthConfig,
  UseLoginOptions,
  UseRegisterOptions,
  UseUserOptions,
} from './types';
import { Login } from 'features/auth/types';

/* 
 This is a baseline for how we can use react query to handle auth
 Most of the code come from "react-query-auth" library but it was not
 compatable with our version of react-query (v5).

 This needs to be revisited when we know exactly how we handle auth.
*/

export function configureAuth<User, Error, LoginCredentials, RegisterCredentials>(
  config: ReactQueryAuthConfig<User, LoginCredentials, RegisterCredentials>
) {
  const {
    userFn,
    userKey = ['authenticated-user'],
    loginFn,
    tokenFn,
    registerFn,
    logoutFn,
  } = config;

  // Get user information
  const useUser = (options?: UseUserOptions<Login>) => {
    return useQuery({ queryKey: userKey, queryFn: userFn, ...options });
  };

  // Login user
  const useLogin = (options?: UseLoginOptions<User, LoginCredentials>) => {
    const queryClient = useQueryClient();

    const setUser = React.useCallback(
      (data: User) => queryClient.setQueryData(userKey, data),
      [queryClient]
    );

    return useMutation({
      mutationFn: loginFn,
      ...options,
      onSuccess: (user, ...rest) => {
        setUser(user);
        options?.onSuccess?.(user, ...rest);
      },
    });
  };

  // Login company select
  const useCompanySelect = (options?: UseLoginOptions<User, LoginCredentials>) => {
    const queryClient = useQueryClient();

    const setUser = React.useCallback(
      (data: User) => queryClient.setQueryData(userKey, data),
      [queryClient]
    );

    return useMutation({
      mutationFn: tokenFn,
      ...options,
      onSuccess: (user, ...rest) => {
        setUser(user);
        options?.onSuccess?.(user, ...rest);
      },
    });
  };

  // Register user
  const useRegister = (options?: UseRegisterOptions<User, RegisterCredentials>) => {
    const queryClient = useQueryClient();

    const setUser = React.useCallback(
      (data: User) => queryClient.setQueryData(userKey, data),
      [queryClient]
    );

    return useMutation({
      mutationFn: registerFn,
      ...options,
      onSuccess: (user, ...rest) => {
        setUser(user);
        options?.onSuccess?.(user, ...rest);
      },
    });
  };

  // Logout user
  const useLogout = (options?: UseMutationOptions<unknown, Error, unknown>) => {
    const queryClient = useQueryClient();

    const setUser = React.useCallback(
      (data: User | null) => queryClient.setQueryData(userKey, data),
      [queryClient]
    );

    return useMutation({
      mutationFn: logoutFn,
      ...options,
      onSuccess: (...args) => {
        setUser(null);
        options?.onSuccess?.(...args);
      },
    });
  };

  const AuthLoader = ({
    children,
    renderLoading,
    renderLogin,
    renderCompanySelect,
    renderError,
  }: AuthLoaderProps) => {
    const { isSuccess, isFetched, isError, data, error } = useUser();

    if (isSuccess) {
      if (renderLogin && !data?.token && !data?.user) {
        return renderLogin();
      }
      if (renderCompanySelect && !data?.token && data?.user) {
        return renderCompanySelect();
      }
      if (data?.token && data?.user) {
        return children;
      }
      return null;
    }

    if (!isFetched) {
      return renderLoading();
    }

    if (isError && renderError) {
      return renderError(error as globalThis.Error);
    }

    return null;
  };

  return {
    useUser,
    useLogin,
    useCompanySelect,
    useRegister,
    useLogout,
    AuthLoader,
  };
}
