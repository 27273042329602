export const colors = {
  white: {
    500: '#FFFFFF',
  },
  black: {
    100: '#666666',
    200: '#4C4D4D',
    300: '#323333',
    400: '#1A191A',
    500: '#000000',
  },
  grey: {
    100: '#E5E5E5',
    200: '#CCCCCC',
    300: '#B3B3B3',
    400: '#999999',
    500: '#808080',
  },
  coral: {
    200: '#F8E3E1',
    300: '#F2C7C3',
    400: '#EBABA6',
    500: '#EE8B86',
  },
  pastelPink: {
    200: '#FBF3F2',
    300: '#F7E9E5',
    400: '#F3DDD9',
    500: '#F3D0CB',
  },
  beige: {
    200: '#FDFCFB',
    300: '#FBF9F7',
    400: '#F9F5F3',
    500: '#F7F2EF',
  },
  palmGreen: {
    200: '#C8D9D8',
    300: '#90B3B1',
    400: '#5A8C8A',
    500: '#166963',
  },
  mintGreen: {
    200: '#EFF3F3',
    300: '#DDE6E6',
    400: '#CCD9DA',
    500: '#B9CECE',
  },
  lavender: {
    200: '#FDF4FD',
    300: '#FAE9FD',
    400: '#F7DEFB',
    500: '#F9D0FC',
  },
  pearl: {
    200: '#FDFAFC',
    300: '#FBF5F8',
    400: '#F9F1F5',
    500: '#F8EBF1',
  },
  marineBlue: {
    200: '#D4D9FF',
    300: '#A8B4FF',
    400: '#7D8EFF',
    500: '#5168FF',
  },
  sunflower: {
    200: '#FFF9ED',
    300: '#FEF3DD',
    400: '#FDECCB',
    500: '#FFE6B5',
  },
  success: {
    200: '#D9F0E1',
    300: '#B4E0C2',
    400: '#90D1A5',
    500: '#58C582',
  },
  error: {
    200: '#F6C7C9',
    300: '#ED8E94',
    400: '#E3565E',
    500: '#DA1E28',
  },
  warning: {
    200: '#FFF7D7',
    300: '#FDEEAE',
    400: '#FDE688',
    500: '#FDDF4E',
  },
};
