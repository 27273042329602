import { colors } from './colors';

declare module '@mui/material/styles' {
  interface Palette {
    danger: Palette['primary'];
  }

  interface PaletteOptions {
    danger?: PaletteOptions['primary'];
  }

  interface PaletteColor {
    contrast?: string;
  }

  interface SimplePaletteColorOptions {
    contrast?: string;
  }
}

export const palette = {
  primary: {
    lighter: colors.palmGreen[400],
    main: colors.palmGreen[500],
    contrast: colors.white[500],
  },
  error: {
    main: colors.error[400],
    light: colors.error[300],
    contrast: colors.white[500],
  },
  success: {
    main: colors.success[500],
    light: colors.success[300],
    contrast: colors.black[500],
  },
};
