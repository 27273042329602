import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes';
import { ReactQueryProvider } from './providers';
import { ThemeProvider, EasyPeasyProvider, I18nProvider } from 'providers';
import { CssBaseline } from '@mui/material';
import { GlobalStyles } from 'styles/globalStyles';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

async function enableMocking() {
  // If we are not on dev, render app normally
  if (import.meta.env.MODE !== 'development') return;

  // Import server worker
  const { worker } = await import('mocks/browser');

  // `worker.start()` returns a Promise that resolves
  // To avoid race conditions, render app after promise is resolved
  return worker.start();
}

enableMocking().then(() => {
  root.render(
    <StrictMode>
      <I18nProvider>
        <ReactQueryProvider>
          <EasyPeasyProvider>
            <ThemeProvider>
              <CssBaseline />
              <GlobalStyles />
              <RouterProvider router={router} />
            </ThemeProvider>
          </EasyPeasyProvider>
        </ReactQueryProvider>
      </I18nProvider>
    </StrictMode>
  );
});
