import * as Sentry from '@sentry/react';
import type { FallbackRender } from '@sentry/react';
import type { ErrorBoundaryProps } from './types';
import { useCallback } from 'react';
import { FallbackComponent } from './fallbackComponent';
import { getUserCookies } from 'features/auth';
import { IS_DEV } from 'config';

const fallbackRender: FallbackRender = props => {
  return <FallbackComponent {...props} />;
};

export const userFallbackRender: FallbackRender = () => {
  return <p>We will add some nice error artwork or something here behind the dialog.</p>;
};

const renderFallback = IS_DEV ? fallbackRender : userFallbackRender;

// Can't use useUser hook here as he dialog renders before
const user = getUserCookies();

export const ErrorBoundary = ({
  dialogShouldRefresh = true,
  children,
  fallback,
  showDialog = true,
  dialogOptions,
  ...rest
}: ErrorBoundaryProps) => {
  const onClose = useCallback(() => {
    dialogShouldRefresh && window.location.reload();
  }, [showDialog, dialogShouldRefresh]);

  return (
    <Sentry.ErrorBoundary
      showDialog={!IS_DEV && dialogShouldRefresh ? showDialog : false}
      dialogOptions={{
        title: 'It looks like we’re having issues.',
        subtitle: 'Our team has been notified.',
        subtitle2: "If you'd like to help, tell us what happened below.",
        labelSubmit: 'Send',
        labelClose: 'Close',
        labelComments: 'What happened?',
        onClose: () => onClose(),
        user: {
          name: user?.loginContext?.name || 'Name',
          email: user?.loginContext?.email || 'Email',
        },
        ...dialogOptions,
      }}
      fallback={fallback ? fallback : renderFallback}
      {...rest}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
